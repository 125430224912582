import React from 'react';
import './LeaguesSlider.css';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }

    return array;
}

function LeaguesSlider({ totalLeagues }) {
  const leaguesPerRow = totalLeagues;

  const generateRow = (direction) => {
    let leagues1 = [];
    for (let i = 0; i < leaguesPerRow; i++) {
      leagues1.push(<img src={`/leagues2/league${i}.png`} alt={`League ${i}`} key={`league_slider_image_${i}_1`} />);
    }
    let leagues2 = [];
    for (let i = 0; i < leaguesPerRow; i++) {
      leagues2.push(<img src={`/leagues2/league${i}.png`} alt={`League ${i}`} key={`league_slider_image_${i}_2`} />);
    }
    let leagues3 = [];
    for (let i = 0; i < leaguesPerRow; i++) {
      leagues3.push(<img src={`/leagues2/league${i}.png`} alt={`League ${i}`} key={`league_slider_image_${i}_3`} />);
    }
    let leagues4 = [];
    for (let i = 0; i < leaguesPerRow; i++) {
      leagues4.push(<img src={`/leagues2/league${i}.png`} alt={`League ${i}`} key={`league_slider_image_${i}_4`} />);
    }
    let leagues5 = [];
    for (let i = 0; i < leaguesPerRow; i++) {
      leagues5.push(<img src={`/leagues2/league${i}.png`} alt={`League ${i}`} key={`league_slider_image_${i}_5`} />);
    }
    return (
      <div className={`slider-row ${direction}`}>
        {[...leagues1, ...leagues2, ...leagues3, ...leagues4, ...leagues5]}
      </div>
    );
  }

  return (
    <div className="leagues-slider">
      {generateRow('slide-right-fast')}
    </div>
  );
}

export default LeaguesSlider;