import React, { useState } from 'react';
import './SectionSwitcher.css';

const SECTIONS = [
  {
    title: 'Secure Wallet',
    description: 'Encrypted and secure. Nobody can access your private key except you.',
    videoSrc: '/sectioned_videos/1.mp4'
  },
  {
    title: 'Cross-Chain Bridge',
    description: 'Move your assets with no limit. Three chains, one bridge.',
    videoSrc: '/sectioned_videos/5.mp4'
  },
  {
    title: 'Fast Betting',
    description: 'Easy process, quick wins. Bet it fast.',
    videoSrc: '/sectioned_videos/2.mp4'
  },
  {
    title: 'Bet History',
    description: 'Track your bets, claim your wins. All in one place.',
    videoSrc: '/sectioned_videos/3.mp4'
  },
  {
    title: 'One-Click Copybetting',
    description: 'Share your bets. Copy others. Win together.',
    videoSrc: '/sectioned_videos/4.mp4'
  }
];

function SectionSwitcher() {
  const [activeSection, setActiveSection] = useState(0);

  const handlePrev = () => {
    setActiveSection(prev => prev > 0 ? prev - 1 : SECTIONS.length - 1);
  };

  const handleNext = () => {
    setActiveSection(prev => (prev + 1) % SECTIONS.length);
  };

  return (
    <>
      <div className="wrapper section-switcher-container">
        <div className="section-switcher">
          <button className="arrow left-arrow" onClick={handlePrev}>
            &larr;
          </button>
          <div className="slider">
            <div className="slide" style={{ transform: `translateX(-${activeSection * 100}%)` }}>
              {SECTIONS.map((section, idx) => (
                <div className="slide-content" key={`section_${idx}`}>
                  <h1>{section.title}</h1>
                  <p>{section.description}</p>
                  <video
                    src={section.videoSrc}
                    autoPlay
                    muted
                    playsInline
                    onEnded={(e) => e.currentTarget.play()}
                  ></video>
                </div>
              ))}
            </div>
          </div>
          <button className="arrow right-arrow" onClick={handleNext}>
            &rarr;
          </button>
        </div>
      </div>
      </>
  );
}

export default SectionSwitcher;
