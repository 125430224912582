import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import VideoContainer from "./components/VideoContainer/VideoContainer";
import LeaguesSlider from "./components/LeaguesSlider/LeaguesSlider";
import SectionSwitcher from "./components/SectionSwitcher/SectionSwitcher";
import AlternatingSections from "./components/AlternatingSections/AlternatingSections";
import Footer from "./components/Footer/Footer";
import ThreeBackground from "./components/ThreeBackground/ThreeBackground";
import Leaderboard from "./components/Leaderboard/Leaderboard";

function App() {
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const sectionRef = useRef(null);

    const openDisclaimer = () => setShowDisclaimer(true);
    const closeDisclaimer = () => setShowDisclaimer(false);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

    function toggleMenu() {
      setMenuOpen(!menuOpen);
    }


  return (
      <Router>
    <div className="app">
        {showDisclaimer && (
            <div className={`disclaimer-overlay ${showDisclaimer ? 'show' : ''}`} onClick={closeDisclaimer}>
    <div className="disclaimer-modal" onClick={(e) => e.stopPropagation()}>
        <div className="disclaimer-content">
                <span className="cross" onClick={closeDisclaimer}>&#10005;</span>
            <h2>Disclaimer</h2>
            <p>The BetIT bot on Telegram is not a substitute for financial or investing advice; rather, it is intended exclusively for instructive and entertaining purposes. The potential risk of losing all or part of the money they invested in cryptocurrency sports betting should be acknowledged by participants. By using this bot, users consent to hold its developers and owners blameless against any damages or repercussions from the law. Participants use the bot at their own risk and there are no assurances of profit. It is advised that users gamble wisely and, if needed, seek professional financial advice.
            </p><p>
"Remember that past performance does not guarantee future results and that trading cryptocurrencies is inherently risky and critical. It is important to conduct independent research, carefully evaluate the influencers you choose to follow, be aware of the risks associated with cryptocurrency trading, and invest only the money you can afford to lose.</p>
        </div>
    </div>
</div>

        )}
        <Routes>
            <Route exact path="/" element={
                <>
                <div style={{"position": "relative"}}>
            <ThreeBackground />
      <header>
        <nav>
            <div className="wrapper">
                <img src="/logo.svg" alt="BetIT Bot" className="logo" />
                <button className="burger-menu" onClick={toggleMenu}>
                    {menuOpen ? (
                        <span className="cross">&#10005;</span>  // Render cross when menu is open
                    ) : (
                        <>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </>
                    )}
                </button>
                <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
                    <li>
                        <a href="https://betit-gitbook.gitbook.io/betit-product-docs/token-information/new-users-guide/" className="nav-list-link">Tutorials</a>
                    </li>
                    <li>
                        <a href="https://betit-gitbook.gitbook.io/betit-product-docs/" className="nav-list-link">Docs</a>
                    </li>
                    <li>
                        <a href="https://x.com/teambetit" className="nav-list-link">Twitter</a>
                    </li>
                    <li>
                        <a href="https://t.me/betitportal" className="nav-list-link">Telegram</a>
                    </li>
                    <li>
                        <a className="nav-list-link" onClick={openDisclaimer}>Disclaimer</a>
                    </li>
                    <li>
                        <a href="/leaderboard" className="nav-list-link">Leaderboard</a>
                    </li>
                    <li>
                        <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0xA3C519683010D59fa54A4A6c4cac0F55cB20BB3F" className="button button-primary">Buy $BETIT</a>
                    </li>
                </ul>
                <div className={`overlay ${menuOpen ? 'show' : ''}`}></div>
            </div>
        </nav>
      </header>
        <ul className={`nav-list-mobile ${menuOpen ? 'show' : ''}`}>
            <li>
                <a href="https://betit-gitbook.gitbook.io/betit-product-docs/token-information/new-users-guide" className="nav-list-link">Tutorials</a>
            </li>
            <li>
                <a href="https://betit-gitbook.gitbook.io/betit-product-docs/" className="nav-list-link">Docs</a>
            </li>
            <li>
                <a href="https://x.com/teambetit" className="nav-list-link">Twitter</a>
            </li>
            <li>
                <a href="https://t.me/betitportal" className="nav-list-link">Telegram</a>
            </li>
            <li>
                <a className="nav-list-link" onClick={openDisclaimer}>Disclaimer</a>
            </li>
            <li>
                <a href="/leaderboard" className="nav-list-link">Leaderboard</a>
            </li>
            <li>
                <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0xA3C519683010D59fa54A4A6c4cac0F55cB20BB3F" className="button button-primary">Buy $BETIT</a>
            </li>
        </ul>
      <section className="intro">
        <h1 className="title-desktop">Bet Easily. Win Big.<br/>Anytime. Anywhere.</h1>
        <h1 className="title-mobile">Bet Easily.<br/>Win Big.</h1>
        <h4 className="title-desktop">Bet effortlessly with our free Telegram bot, designed for quick and easy betting while you're on the move. Enjoy the convenience of placing bets with remarkable speed, wherever you are.</h4>
          <div className="intro-buttons">
            <a href="https://t.me/BetIT_tgBot" className="button button-secondary">Start Betting &rarr;</a>
            <a className="button button-primary" onClick={scrollToSection}>Explore Features &darr;</a>
          </div>
      </section></div>
        <div style={{"width": "100%", "background-color": "#000000"}}>
        <LeaguesSlider totalLeagues={17} />
        <div ref={sectionRef}><SectionSwitcher /></div>


        <AlternatingSections />
        <Footer openDisclaimer={openDisclaimer} />
            </div>

        </>} />
            <Route path="/leaderboard" element={
                <>

                    <div style={{"width": "100%", "background-color": "#000000"}}>
                <header>
        <nav>
            <div className="wrapper">
                <img src="/logo.svg" alt="BetIT Bot" className="logo" />
                <button className="burger-menu" onClick={toggleMenu}>
                    {menuOpen ? (
                        <span className="cross">&#10005;</span>  // Render cross when menu is open
                    ) : (
                        <>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </>
                    )}
                </button>
                <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
                    <li>
                        <a href="https://betit-gitbook.gitbook.io/betit-product-docs/token-information/new-users-guide/" className="nav-list-link">Tutorials</a>
                    </li>
                    <li>
                        <a href="https://betit-gitbook.gitbook.io/betit-product-docs/" className="nav-list-link">Docs</a>
                    </li>
                    <li>
                        <a href="https://x.com/teambetit" className="nav-list-link">Twitter</a>
                    </li>
                    <li>
                        <a href="https://t.me/betitportal" className="nav-list-link">Telegram</a>
                    </li>
                    <li>
                        <a className="nav-list-link" onClick={openDisclaimer}>Disclaimer</a>
                    </li>
                    <li>
                        <a href="/leaderboard" className="nav-list-link">Leaderboard</a>
                    </li>
                    <li>
                        <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0xA3C519683010D59fa54A4A6c4cac0F55cB20BB3F" className="button button-primary">Buy $BETIT</a>
                    </li>
                </ul>
                <div className={`overlay ${menuOpen ? 'show' : ''}`}></div>
            </div>
        </nav>
      </header>
        <ul className={`nav-list-mobile ${menuOpen ? 'show' : ''}`}>
            <li>
                <a href="https://betit-gitbook.gitbook.io/betit-product-docs/token-information/new-users-guide" className="nav-list-link">Tutorials</a>
            </li>
            <li>
                <a href="https://betit-gitbook.gitbook.io/betit-product-docs/" className="nav-list-link">Docs</a>
            </li>
            <li>
                <a href="https://x.com/teambetit" className="nav-list-link">Twitter</a>
            </li>
            <li>
                <a href="https://t.me/betitportal" className="nav-list-link">Telegram</a>
            </li>
            <li>
                <a className="nav-list-link" onClick={openDisclaimer}>Disclaimer</a>
            </li>
            <li>
                <a href="/leaderboard" className="nav-list-link">Leaderboard</a>
            </li>
            <li>
                <a href="https://app.uniswap.org/swap?&chain=mainnet&use=v2&outputCurrency=0xA3C519683010D59fa54A4A6c4cac0F55cB20BB3F" className="button button-primary">Buy $BETIT</a>
            </li>
        </ul>
                        <Leaderboard />
                    <Footer openDisclaimer={openDisclaimer} />
                    </div>
                </>
            } />
        </Routes>
    </div>
      </Router>
  );
}

export default App;
