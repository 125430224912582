import React from 'react';
import './AlternatingSections.css';

const SECTIONS = [
  {
    title: 'Revenue Share',
    description: '70% of the trading revenue will be distributed to token holders. Additionally, 10% of the trading revenue will serve as rewards for our top bettors in weekly tournaments, motivating increased participation, and 20% will be distributed equally for referral rewards and tournaments. Additionally, holders are entitled to 100% of the total revenue from betting fees generated through Overtime Markets and Azuro.\n',
    link: ['https://betit-gitbook.gitbook.io/betit-product-docs/token-information/revenue-share'],
      buttonText: ["Read More"],
    videoSrc: '/animations/revshare.mp4'
  },
  {
    title: 'Referral Program\n',
    description: 'When you create an account, we provide you with a personalized referral link, exclusively for you to share with friends and others. When someone uses your unique referral link to sign up, we keep a record of their activity. As a gesture of gratitude for your generosity, you\'ll receive an 8% share of the fees generated by those who joined our platform through your referral link. So, not only can you enjoy our services, but you can also earn a little extra by spreading the word.',
    link: ['https://betit-gitbook.gitbook.io/betit-product-docs/token-information/referral-program'],
      buttonText: ["How to Participate"],
    videoSrc: '/animations/referral.mp4'
  },
  {
    title: 'Tournaments',
    description: 'Users will have the exciting opportunity to engage in weekly tournaments, where they stand a chance to secure substantial prizes for their successful bets. To be eligible for participation, users will need to meet specific criteria, including achieving high odds, placing substantial bets, having numerous winning bets, and maintaining winning streaks. These criteria make the tournaments competitive and offer multiple ways for users to win big.\n',
    link: ['https://t.me/BetIT_tgBot'],
      buttonText: ["Compete"],
    videoSrc: '/animations/tournament.mp4'
  },
  {
    title: 'Myriad of Betting Markets\n',
    description: 'BetIT has made significant progress by seamlessly integrating two of the industry\'s most prominent betting market protocols, Azuro and Overtime. As a result of this integration, a portion of the revenue generated by the massive betting volumes on both of these protocols will be generously distributed to our token holders. We\'ve provided direct links to Azuro and Overtime, allowing you to learn more about their potential benefits and ensure you\'re well-informed for a better BetIT experience.',
    link: ['https://gem.azuro.org/', 'https://docs.overtimemarkets.xyz/'],
    buttonText: ["Azuro Protocol", "Overtime Markets"],
    videoSrc: '/animations/sports.mp4'
  }
];

function AlternatingSections() {
  return (
    <div className="alternating-sections">
      <div className="wrapper">
        {SECTIONS.map((section, idx) => (
          <div key={`alternating_section_${idx}`} className={`section ${idx % 2 === 0 ? 'text-left' : 'text-right'}`}>
            <div className="text-content">
              <h1>{section.title}</h1>
              <p>{section.description}</p>
              <div className="text-content_links">
                {section.link.map((link, linkIdx) => (
                  <a key={`link_${linkIdx}`} href={link} target="_blank" rel="noopener noreferrer">{section.buttonText[linkIdx]} &rarr;</a>
                ))}
              </div>
            </div>
            <div className="video-content">
              <video src={section.videoSrc} autoPlay muted playsInline loop></video>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlternatingSections;
