import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './Leaderboard.css';

function Leaderboard() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    Papa.parse('./BETIT_scores.csv', {
      download: true,
      header: true,
      complete: (results) => {
        // Assigning a rank to each row
        const rankedData = results.data.map((row, index) => ({ ...row, rank: index + 1 }));
        setData(rankedData);
      }
    });
  }, []);

  const filteredData = search
    ? data.filter((row) => row.HolderAddress && row.HolderAddress.toLowerCase().includes(search.toLowerCase()))
    : data;

  return (
    <div className="leaderboard wrapper">
      <h1>Leaderboard</h1>
      <h4>Hold and accumulate across snapshots to increase your $BETIT score</h4>
      <div className="search-container">
  <input
    type="text"
    className="search-input"
    placeholder="Search by Address"
    value={search}
    onChange={(e) => setSearch(e.target.value)}
  />
</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Wallet Address</th>
              <th>$BETIT Score</th>
              <th>% of Revenue Share</th>
              <th>Balance [Snapshot 1]</th>
              <th>Balance [Snapshot 2]</th>
              <th>Balance [Snapshot 3]</th>
              <th>Balance [Snapshot 4]</th>
              <th>Balance [Snapshot 5]</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr key={row.rank} style={{
        animation: 'fadeInUp 0.5s ease-out forwards',
        animationDelay: `${0.1 * index}s`
      }}>
                <td>{row.rank}</td>
                <td><a href={`https://etherscan.io/address/${row.HolderAddress}`} target="_blank" rel="noopener noreferrer">{row.HolderAddress.substring(0, 6)}...{row.HolderAddress.substring(row.HolderAddress.length - 4)}</a></td>
                <td style={{ fontWeight: 'bold', color: 'yourColorHere' }}>{parseInt(Number(row.BETIT_Score))}</td>
                <td>{Number(row.Percentage_of_Total).toFixed(2)}%</td>
                <td>{Number(row.Balance_1).toFixed(2)}</td>
                <td>{Number(row.Balance_2).toFixed(2)}</td>
                <td>{Number(row.Balance_3).toFixed(2)}</td>
                <td>{Number(row.Balance_4).toFixed(2)}</td>
                <td>{Number(row.Balance_5).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leaderboard;