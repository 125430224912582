import React from 'react';
import './Footer.css';




function Footer({openDisclaimer}) {
  return (
      <footer className="footer-container">
        <h1 style={{"text-align": "center", "margin-bottom": "30px"}}>Socials</h1>
        <ul className="footer_icons">
          <li className="footer_icon">
            <a href="https://t.me/betitportal"><img src="/icons/telegram.svg" alt="BetIT Bot" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://t.me/BetIT_tgBot"><img src="/icons/bot.svg" alt="Telegram Group" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://x.com/teambetit"><img src="/icons/twitter-x.svg" alt="Twitter / X" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xc3eedc4c69c6936a4a71fe67a23b8ea023c3b0e6"><img src="/icons/dextools.svg" alt="Dextools Chart" /></a>
          </li>
          <li className="footer_icon">
            <a href="https://betit-gitbook.gitbook.io/betit-product-docs/"><img src="/icons/docs.svg" alt="Docs" /></a>
          </li>
          <li className="footer_icon">
            <a href="mailto:teambetit@gmail.com"><img src="/icons/gmail.svg" alt="Contact Us" /></a>
          </li>
        </ul>
        <div className="footer_branding-section wrapper">
          <div className="footer_branding-section_left">
            <img src="/logo-img.svg" alt="BetIT" />
            <p>By accessing our services, you accept our <a onClick={openDisclaimer}>Terms of Service</a>.</p>
          </div>
          <div className="footer_branding-section_right">
            <p>© 2023 BetIT. All rights reserved.</p>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
